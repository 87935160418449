@use "@styles/partials/functions" as *;

.root {
  display: flex;
  align-items: center;
  color: var(--secondary-main);
}

.rootDense {
  display: block;
}

.body {
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  font-size: rem(14);
  line-height: 100%;
}

.bodyDense {
  font-size: rem(12);
  white-space: normal;
}
