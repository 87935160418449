@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.rootRibbon {
  background-color: var(--secondary-main);
  border-radius: 2px;
  display: flex;
  align-items: center;
  color: var(--common-white);
  position: relative;
  height: 24px;
  width: 220px;
  padding: spacing(0, 1);

  @include devices(sm-up) {
    height: 26px;
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 2px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--contextual-colors-negative);

    @include devices(sm-up) {
      top: 3px;
    }
  }
}
