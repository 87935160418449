@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.rootCoupon {
  height: 70px;
  position: relative;
  border: 1px solid var(--secondary-main);
  border-left: none;
  border-right: none;

  & .couponIconWrapper {
    height: 70px;
    width: 77px;
  }

  & .percentIcon {
    width: 40px;
    height: 40px;
  }
}

.rootCouponWithSwitcher {
  border-right: 1px solid;

  & .couponBody {
    padding-right: 40px;
  }
}

.rootCouponDense {
  height: 26px;

  & .couponIconWrapper {
    height: 26px;
    width: 28px;
  }

  & .percentIcon {
    width: 16px;
    height: 16px;
  }
}

.rootCouponHeader {
  height: 70px;
  display: flex;
  border-bottom: none;

  & .couponIconWrapper {
    width: 77px;
    height: 70px;
  }
}

.rootCouponHeaderDetailed {
  height: 80px;

  & .couponIconWrapper {
    width: 88px;
    height: 80px;
  }
}

.headerPromotionBaseBody {
  white-space: normal;
}

.couponIconWrapper {
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.couponBody {
  padding: spacing(0, 0.75);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background-color: var(--secondary-light);
  text-align: left;
}

.couponBodyWide {
  width: 244px;
}

.tooltipIcon {
  margin-left: 5px;
  margin-right: 0;
  color: var(--primary-main);
}

.couponExpertName {
  color: var(--common-black);
  font-size: rem(12);
  line-height: 1;
  margin-top: 4px;
}

.root {
  display: flex;
  align-items: center;
}

.warnIcon {
  width: 15px;
  height: 15px;
  margin-left: spacing(0.5);
  display: inline-block;
  vertical-align: text-top;
}

.expiresText {
  font-size: rem(12);
  margin-top: spacing(1);
}

.promotionCouponIcon.promotionCouponIcon {
  width: 100%;
  height: 100%;
}

.circle {
  position: relative;
  height: 100%;
  width: 9px;
  overflow: hidden;
  display: flex;
  align-items: center;

  &:after {
    content: "";
    position: absolute;
    height: 70px;
    width: 70px;
    background-color: transparent;
    border-radius: 50%;
    border: 24px solid var(--secondary-main);
  }
}

.circleDetailed {
  &:after {
    border-width: 20px;
  }

  &.circleRight,
  .circleRightMask {
    &:after {
      border-width: 20px;
      right: -42px;
    }
  }
}

.circleRight {
  &:after {
    right: -38px;
  }
}

.circleRightMask {
  right: 1px;
  z-index: 1;

  &:after {
    border: 24px solid var(--secondary-light);
    right: -38px;
  }
}

.circleDense {
  width: 5px;

  &:after {
    width: 44px;
    height: 44px;
    border-width: 16px;
  }

  &.circleRight,
  .circleRightMask {
    &:after {
      border-width: 16px;
      right: -24px;
    }
  }
}
.circleRightHeader {
  width: 12px;

  &:after {
    width: 88px;
    height: 88px;
    right: -52px;
    border-width: 26px;
  }

  .circleRightMask {
    width: 12px;

    &:after {
      width: 88px;
      height: 88px;
      right: -52px;
      border-width: 26px;
    }
  }
}
