@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.iconWrapper {
  width: 16px;
  height: 16px;
  display: inline-flex;

  @include devices(md-up) {
    position: relative;
  }
}

.iconWrapperLeft {
  margin-right: 5px;
}

.iconWrapperRight {
  margin-left: 5px;
  display: none;

  @include devices(s-up) {
    display: inline-flex;
  }
}

.iconDense {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}
